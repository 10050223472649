export enum KnowledgeKeys {
    ZuchtlaborMeaning,
    ZuchtlaborMap1,
    ZuchtlaborMap2,
    ZuchtlaborMap3,
    HolyMoleMap1,
    HolyMoleMap2,
    HolyMoleMap3,
    EcolibriumMapTerrasse,
    EcolibriumMapStock4,
    EcolibriumMapStock3,
    EcolibriumMapStock2,
    EcolibriumMapStock1,
    EcolibriumMapEG,
    EcolibriumMapStorage
}

export enum EntryUnlocks {
    //Creatures
    CreatureWasserfuchs,
    CreatureKrangusteln,
    CreatureQuafquaf,
    CreatureKogniQualle,
    CreatureBlob,
    //Locations
    LocationHolyMole,
    LocationZuchtlabor,
    LocationEcolibrium
}

export interface CodexState {
    KnowledgeKeys:KnowledgeKeys[]
    UnlockedEntries:EntryUnlocks[],
    IsDM:Boolean
}

export const KnowsEntry = (Codex: CodexState, EntryUnlocks: EntryUnlocks) => Codex.IsDM || Codex.UnlockedEntries.includes(EntryUnlocks)
export const Knows = (Codex: CodexState, KnowledgeKey: KnowledgeKeys) => Codex.IsDM || Codex.KnowledgeKeys.includes(KnowledgeKey)