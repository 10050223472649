import { FC } from "react";
import { CCharacterThumbnail } from "./CCharacterThumbnail";
import { CharacterState } from "../../Classes/CharacterState";


export const CGroupMemberSelectionDialog : FC<{groupMembers:CharacterState[], onSelected:(characterId:string)=>void, OwnCharacterId?:string, selectedId?:string}> = ({groupMembers,onSelected,OwnCharacterId,selectedId}) => {
    
    const OwnCharacter = groupMembers.find(c => c.Id === OwnCharacterId)

    return (
        <>
            {OwnCharacter !== undefined && 
                <div style={{ marginInline: 15, marginBottom: 20 }} className="wrappable-container">
                    <CCharacterThumbnail selected={selectedId === OwnCharacterId} character={OwnCharacter} width={400} key={'mychar'} onClick={() => onSelected(OwnCharacter.Id)} />
                </div>
            }
            <div style={{ marginInline: 15, marginBottom: 20 }} className="wrappable-container">
                {groupMembers.filter(c => c.Id !== OwnCharacterId).map((char, i) => <CCharacterThumbnail character={char} selected={char.Id === selectedId} width={200} onClick={() => onSelected(char.Id)} key={char.Name + i} />)}
            </div>
        </>
    )
}