import { FC } from "react"
import { GroupState, Map, MapCoordinate } from "../../Classes/GroupState"
import { Card } from "react-bootstrap"
import { CClickableImage, DataPoint } from "../Utility/CClickableImage"
import { GroupApi } from "../../Classes/GroupApi"
import { CharacterState } from "../../Classes/CharacterState"
import { NpcState } from "../../Classes/NpcState"

const groupApi = new GroupApi()

export const CGroupMap: FC<{ GroupState: GroupState, OwnCharacterId: string, DmMode?:boolean }> = ({ GroupState, OwnCharacterId, DmMode }) => {

    const NoMapBody = (
        <Card key={'map-none'} style={{ maxWidth: '100%' }}>
            <Card.Body>
                <Card.Title>Keine Karte ausgewählt</Card.Title>
            </Card.Body>
        </Card>
    )

    const pointConversionFromCharacter = (c: CharacterState): DataPoint => {
        return { X: c.Position!.X, Y: c.Position!.Y, Name: c.Name, ImageName:c.Name }
    }

    const pointConversionFromNpc = (c: NpcState): DataPoint => {
        return { X: c.Position!.X, Y: c.Position!.Y, Name: c.Name, Color: 'red', ImageName:c.ImageName }
    }

    const onMapClicked = (p: MapCoordinate) => {
        groupApi.SetPosition(p, OwnCharacterId)
    }

    const getDataPointsFor = (m: Map): DataPoint[] => {
        const players = GroupState.GroupMembers.filter(c => c.Position?.MapId === m.Id)
        const npcs = GroupState.NpcStates.filter(c => c.Position?.MapId === m.Id)

        return [...players.map(pointConversionFromCharacter), ...npcs.filter(c => c.Active || DmMode).map(pointConversionFromNpc)]
    }

    return (
        <div style={{ marginInline: 15, marginBottom: 20 }} className="wrappable-container">
            {GroupState.Maps ? (
                GroupState.Maps.filter(m => m.Active || DmMode).map(m =>
                    <Card key={'map'+m.Id} style={{ maxWidth:500, width:'100%' }}>
                        <Card.Body>
                            <Card.Title>{m.MapName}</Card.Title>
                            <CClickableImage imgSrc={`/images/${m.MapImageName}.png`} datapoints={getDataPointsFor(m)} onClick={(c) => onMapClicked({ ...c, MapId: m.Id })} />
                        </Card.Body>
                    </Card>
                )
            ) : NoMapBody}
        </div>
    )
}