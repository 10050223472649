import { FC } from "react";
import { Card } from "react-bootstrap";
import { CLocationEntry } from "./CLocationEntry";
import { AdditionalCard } from "../../../Classes/AdditionalCard";
import { KnowledgeKeys } from "../../../Classes/CodexState";

export const CHolyMoleEntry : FC = () => {

    const DescriptionText = <>
        Holy-Mole war eine der ersten stationären Einrichtungen auf Demeter. Der Name entstand durch eine verlorenen Wette und eine Eröffnungsfeier mit zu viel Alkohol.
        Doch schon bald waren die Mitarbeiter und Bewohner von Holy-Mole Stoltz auf den Namen der Basis. Jeder von ihnen trägt ständig das Emblem der Basis bei sich.
        <Card.Img src="/images/HolyMoleLogo.jpeg"/>
    </>

    const Maps : AdditionalCard[] = [
        {
            Title:"Ausßenbereich",
            DescriptionText:<>
                
            </>,
            ImageName:"HolyMoleMap1.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.HolyMoleMap1 ]
        },
        {
            Title:"Haupthalle",
            DescriptionText:<>
                
            </>,
            ImageName:"HolyMoleMap2.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.HolyMoleMap2 ]
        },
        {
            Title:"Oberes Stockwerk",
            DescriptionText:<>
                
            </>,
            ImageName:"HolyMoleMap3.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.HolyMoleMap3 ]
        }
    ]

    return <CLocationEntry Name={"HolyMole"} DescriptionText={DescriptionText} AdditionalCards={Maps}/>
}