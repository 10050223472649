import { FC, useState } from "react";
import { Item, Items } from "../../Classes/CharacterState";
import { Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faWeight } from "@fortawesome/free-solid-svg-icons";
import { CMedkitUsing } from "../Codex/Items/ItemUsings/CMedkitUsing";
import { CEquipWeaponUsing } from "../Codex/Items/ItemUsings/CEquipWeaponUsing";

export const CItemStorage: FC<{ items: {Item:Item, Amount:number}[], isOwnStorage?: boolean, ownerId:string }> = ({ items, isOwnStorage, ownerId }) => {
    const [shownItemName, setShownItemName] = useState<Item|undefined>(undefined);
    const [shownUseItem, setShownUseItem] = useState<Item|undefined>(undefined);
    
    const itemToElement = (kv: {Item:Item, Amount:number}) => (
        <tr key={kv.Item.Name}>
            <td><img onClick={() => setShownUseItem(kv.Item)} width={50} height={50} src={`/images/${kv.Item.Name}.png`} alt={kv.Item.Name} /></td>
            <td><FontAwesomeIcon style={{marginRight:5}} icon={faInfoCircle} onClick={() => setShownItemName(kv.Item)} />{`${kv.Amount}x`} {kv.Item.Name}</td>
            <td><FontAwesomeIcon style={{marginRight:5}} icon={faWeight} />{kv.Item.Unhandiness*kv.Amount}</td>
        </tr>
    )

    const weight = items.map(i => i.Item.Unhandiness * i.Amount).reduce((partialSum, a) => partialSum + a, 0)

    const ItemUsageDialog = (item: Item) => {

        switch (item.Name) {
            case Items.Medkit.Item.Name:
                return <CMedkitUsing onUse={()=>setShownUseItem(undefined)} CharacterId={ownerId}/>
            case Items.BulkSN.Item.Name:
                return <CEquipWeaponUsing onUse={()=>setShownUseItem(undefined)} Weapon={Items.BulkSN.Weapon} CharacterId={ownerId}/>
            case Items.P2000.Item.Name:
                return <CEquipWeaponUsing onUse={()=>setShownUseItem(undefined)} Weapon={Items.P2000.Weapon} CharacterId={ownerId}/>
            case Items.CCChainsaw.Item.Name:
                return <CEquipWeaponUsing onUse={()=>setShownUseItem(undefined)} Weapon={Items.CCChainsaw.Weapon} CharacterId={ownerId}/>
            default:
                return <>Kann nicht verwendet werden...</>
        }
    }

    return <>
        <Table>
            <thead>
                <tr>
                    <th colSpan={2}>Items <FontAwesomeIcon style={{marginRight:5, marginLeft:20}} icon={faWeight} />{weight}</th>
                </tr>
            </thead>
            <tbody>
                {items.map(itemToElement)}
            </tbody>
        </Table>
        <Modal show={shownItemName !== undefined || (isOwnStorage && shownUseItem !== undefined)} onHide={() => {setShownItemName(undefined);setShownUseItem(undefined)}}>
            <Modal.Header closeButton />
            <Modal.Body>
                {shownItemName && (Items as any)[shownItemName.Name].Description}
                {shownUseItem && ItemUsageDialog(shownUseItem)}
            </Modal.Body>
        </Modal>
    </>
}

