import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { CodexState, EntryUnlocks, KnowsEntry } from "../../../Classes/CodexState";
import { CCreatureEntry } from "./CCreatureEntry";
import { CodexApi } from "../../../Classes/CodexApi";

export const CQuafquafEntry : FC = () => {

    const [context] = useState<CodexState>(CodexApi.FromCache())

    const DescriptionText = <>
        Diese auf <Link to="/Codex/Poseidon">Poseidon</Link> lebend Kreaturen sind zwar etwas grob, aber zweifelsfrei intelligente Lebensformen.
        Die Ehre ihres Stamms ist ihnen das wichtigste und sie werden sie auf den Tod verteidigen.<br/>
        {KnowsEntry(context, EntryUnlocks.CreatureKrangusteln) && <>
            <br/>Die Quafquaf und die <Link to="/Codex/Krangusteln">Krangusteln</Link> können sich jedoch überhaupt nicht leiden und bekämpfen sich von Zeit zu Zeit.
        </>}
    </>

    return <CCreatureEntry Name="Quafquaf" DescriptionText={DescriptionText}/>
}