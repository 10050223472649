import { CharacterState } from "./CharacterState";
import { NpcState } from "./NpcState";

export interface GroupState {
    GroupMembers: CharacterState[],
    Maps: Map[],
    NpcStates:NpcState[]
}

export interface Map {
    Id:string
    MapName: string,
    MapImageName: string,
    Active:boolean
}

export const MapNames = [
    "EcolibriumMapTerrasse",
    "EcolibriumMapStock4",
    "EcolibriumMapStock3",
    "EcolibriumMapStock2",
    "EcolibriumMapStock1",
    "EcolibriumMapEG",
    "EcolibriumMapStorage",
    "HolyMoleMap1",
    "HolyMoleMap2",
    "HolyMoleMap3"
]

export interface Coordintate {
    X: number,
    Y: number
}

export interface MapCoordinate extends Coordintate {
    MapId:string
}