import { FC } from "react";
import { Link } from "react-router-dom";
import { CCreatureEntry } from "./CCreatureEntry";

export const CKogniQualleEntry : FC = () => {

    const DescriptionText = <>
        Wird die KogniQualle in die Nähe eines Hirns eines Lebewesens gelassen, so kann sie damit interagieren. Meist geht sie eine Symbiose ein.
        Sie teilt temporär ihr Wissen mit dem Wirt, während sie sich dessen wissen einverleibt. So können die Wirtswesen oft mit unterschiedlichsten Spezies kommunizieren,
        während sie mit Kogni verbunden sind. Kogni ist sehr selten in dem Meeren von <Link to="/Codex/Poseidon">Poseidon</Link> zu finden.
    </>

    return <CCreatureEntry Name="KogniQualle" DescriptionText={DescriptionText}/>
}