import { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export const CImageSelection: FC<{ imageNames: string[], selected?: string, onChanged: (imageName: string) => void, selectionBtnText?: string }> = ({ imageNames, selected, onChanged, selectionBtnText }) => {

    const [shown, setShown] = useState(false)

    return (
        <>
            <Button style={{ marginInline: 10 }} onClick={() => setShown(true)}>Bild wählen</Button>
            {selected && <img key={'image-selected'} src={`/images/${selected}.png`} alt={selected} width={50} height={50} />}
            <Modal show={shown} onHide={() => setShown(false)}>
                <Modal.Header closeButton>{selectionBtnText === undefined ? 'Wähle ein Bild' : selectionBtnText}</Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
                        {imageNames.map(imgName =>
                            <img onClick={() => onChanged(imgName)} key={'image-' + imgName} src={`/images/${imgName}.png`} alt={imgName} width={100} height={100} style={{ filter: imgName === selected ? '' : 'grayscale(100%)' }} />
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}