import { FC, useEffect, useState } from "react";
import { GroupApi, RefreshRate } from "../../Classes/GroupApi";
import { GroupState } from "../../Classes/GroupState";
import { CLoading } from "../Utility/CLoading";
import { Modal } from "react-bootstrap";
import { CCharacterOverview } from "./CCharacterOverview";
import { CGroupMap } from "./CGroupMap";
import { CGroupMemberSelectionDialog } from "./CGroupMemberSelectionDialog";
import { CNpcSelectionDialog } from "../DungeonMaster/CNpcSelectionDialog";

const groupApi = new GroupApi()
const controller = new AbortController()

export const CGroupOverview: FC<{OwnCharacterId:string}> = ({OwnCharacterId}) => {

    const [groupState, setGroupState] = useState<GroupState | undefined>(undefined);
    const [selectedCharacterId, setSelectedCharacterId] = useState<string|undefined>(undefined);

    useEffect(() => {
        const signal = controller.signal
        const timer = setInterval(()=>groupApi.GetGroup(signal).then(group => setGroupState(group)), RefreshRate)
        
        return () => {
            clearInterval(timer)
            controller.abort()
        }
    }, [])
    
    if (groupState === undefined) {
        return <CLoading Message="Funkt die Gruppe an..." />
    }

    return (
        <>
            <h1 style={{textAlign:'center'}}>Gruppe</h1>
            <CGroupMemberSelectionDialog groupMembers={groupState.GroupMembers} onSelected={characterId => setSelectedCharacterId(characterId)} OwnCharacterId={OwnCharacterId}/>
            <CGroupMap GroupState={groupState} OwnCharacterId={OwnCharacterId} />
            <h1 style={{textAlign:'center'}}>NPCs</h1>
            <CNpcSelectionDialog npcs={groupState.NpcStates.filter(npc => npc.Active)} onSelected={()=>{}} hideHealthBarOnEnemy={true}/>
            <Modal show={selectedCharacterId !== undefined} onHide={() => setSelectedCharacterId(undefined)}>
                <Modal.Header closeButton />
                <Modal.Body>
                    {selectedCharacterId !== undefined && (
                        <CCharacterOverview 
                            isOwnCharacter={selectedCharacterId === OwnCharacterId}
                            character={groupState.GroupMembers.find(c => c.Id === selectedCharacterId)!}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}