import { CodexState } from "./CodexState"

export class CodexApi {
    public GetCodex(): Promise<CodexState> {
        return new Promise<CodexState>((res, rej) => {
            const result = GetContextFromStorage()
            SaveContextToStorage(result)
            res(result)
        })
    }

    public static FromCache(): CodexState {
        return GetContextFromStorage()
    }
}

const CharacterStorageKey = "Codex"
const GetContextFromStorage = (): CodexState => {
    const result = localStorage.getItem(CharacterStorageKey)

    if (result === null) {
        const initial = { IsDM: true, KnowledgeKeys: [], UnlockedEntries: [] }
        SaveContextToStorage(initial)
        return initial
    }

    return JSON.parse(result)
}

const SaveContextToStorage = (CharacterState: CodexState): void => {
    localStorage.setItem(CharacterStorageKey, JSON.stringify(CharacterState))
}