import { FC } from "react";
import { NpcState } from "../../Classes/NpcState";
import { CNpcThumbnail } from "./CNpcThumbnail";

export const CNpcSelectionDialog: FC<{ npcs: NpcState[], onSelected: (npcId: string) => void, selectedId?: string, hideHealthBarOnEnemy?:boolean }> = ({ npcs, onSelected, selectedId, hideHealthBarOnEnemy }) => {
    return (
        <div style={{ marginInline: 15, marginBottom: 20 }} className="wrappable-container">
            {npcs.map((char, i) => <CNpcThumbnail npc={char} selected={selectedId === char.Id} width={200} onClick={() => onSelected(char.Id)} key={char.Name + i} hideHealthBarOnEnemy={hideHealthBarOnEnemy} />)}
        </div>
    )
}