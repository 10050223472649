import { FC } from "react";
import { CItemEntry } from "./CItemEntry";

export const CHelperBotEntry : FC = () => {

    const DescriptionText = <>
        Diese 75cm großen Roboter werden von dem Menschen verwenden um alle möglichen Aufgaben auszuführen.
        Obwohl sie eine rudimentäre generelle KI eingebaut haben sollte man ihnen am besten ganz genau sagen,
        was sie tun sollen. Es ist schon das ein oder andere Mal passiert, dass ein Helper-Bot sein Anweisungen "zu wörtlich" genommen hat.
    </>

    return <CItemEntry Name={"Helper Bot"} DescriptionText={DescriptionText} />
}