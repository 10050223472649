import { FC, useEffect, useState } from "react";
import { CGroupMemberSelectionDialog } from "../../../Character/CGroupMemberSelectionDialog";
import { GroupState } from "../../../../Classes/GroupState";
import { GroupApi } from "../../../../Classes/GroupApi";
import { CLoading } from "../../../Utility/CLoading";
import { Items } from "../../../../Classes/CharacterState";

const groupApi = new GroupApi()
const controller = new AbortController()

export const CMedkitUsing: FC<{ onUse: () => void, CharacterId: string }> = ({ onUse, CharacterId }) => {

    const [groupState, setGroupState] = useState<GroupState | undefined>(undefined);
    const [healing, setHealing] = useState<boolean>(false);

    const onMemberSelecter = async (receivingCharId: string) => {
        setHealing(true);
        const waitHandle = waitAsync()
        await groupApi.AddHealth(5, receivingCharId);
        await groupApi.RemoveItem(Items.Medkit.Item.Name, CharacterId)
        await waitHandle
        onUse()
    }

    const waitAsync = (): Promise<boolean> => {
        return new Promise<boolean>(async (res, rej) => {
            setTimeout(() => res(true), 2500)
        })
    }

    useEffect(() => {
        const signal = controller.signal
        groupApi.GetGroup(signal).then(group => setGroupState(group))
    }, [])

    if (groupState === undefined) {
        return <CLoading Message="Funkt die Gruppe an..." />
    }

    if (healing) {
        return <CLoading Message="Verbindet den Patenten..." ImageName="UseMedkit" />
    }

    return <CGroupMemberSelectionDialog groupMembers={groupState.GroupMembers} onSelected={onMemberSelecter} />
}