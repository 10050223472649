import { FC } from "react";
import { CLocationEntry } from "./CLocationEntry";
import { AdditionalCard } from "../../../Classes/AdditionalCard";
import { KnowledgeKeys } from "../../../Classes/CodexState";
import { CClickableImage } from "../../Utility/CClickableImage";

export const CEcolibriumEntry : FC = () => {

    const DescriptionText = <>
        
    </>

    const Maps : AdditionalCard[] = [
        {
            Title:"Terrasse",
            DescriptionText:<>
                
            </>,
            ImageName:"EcolibriumMapTerrasse.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapTerrasse ]
        },
        {
            Title:"Stock 4",
            DescriptionText:<>
                
            </>,
            ImageName:"EcolibriumMapStock4.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapStock4 ]
        },
        {
            Title:"Stock 3",
            DescriptionText:<>
                
            </>,
            ImageName:"EcolibriumMapStock3.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapStock3 ]
        },
        {
            Title:"Stock 2",
            DescriptionText:<>
                
            </>,
            ImageName:"EcolibriumMapStock2.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapStock2 ]
        },
        {
            Title:"Stock 1",
            DescriptionText:<>
                
            </>,
            ImageName:"EcolibriumMapStock1.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapStock1 ]
        },
        {
            Title:"Erdgeschoss",
            DescriptionText:<>
                {EGImage}
            </>,
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapEG ]
        },
        {
            Title:"Lager",
            DescriptionText:<>
                {LagerImage}
            </>,
            NeededKnowledgeKeys:[ KnowledgeKeys.EcolibriumMapStorage ]
        },
    ]

    return <CLocationEntry Name={"Ecolibrium"} DescriptionText={DescriptionText} AdditionalCards={Maps}/>
}

const LagerImage = <CClickableImage imgSrc="/images/EcolibriumMapStorage.png" datapoints={[
    { Name:"Waffenkammer", Link:"", X:7, Y:28, Color:'Black' },
    { Name:"Anzugsraum", Link:"", X:6, Y:53 }
]}/>

const EGImage = <CClickableImage imgSrc="/images/EcolibriumMapEG.png" datapoints={[
    { Name:"3D-Drucker", X:9, Y:49 },
    { Name:"Fusionsreactor", X:50, Y:90 }
]}/>