import { FC } from "react";
import { WeaponState } from "../../Classes/WeaponState";
import { Button, Card, ProgressBar } from "react-bootstrap";
import { GroupApi } from "../../Classes/GroupApi";
import { CharacterState, Items } from "../../Classes/CharacterState";

const groupApi = new GroupApi()

export const CWeaponThumbnail: FC<{ Weapon: WeaponState, Character: CharacterState }> = ({ Weapon, Character }) => {

    const onTryShoot = async () => {
        const remainingAmmo = Character.Weapon!.Ammo

        if (remainingAmmo <= 0) {
            window.alert('Waffe leer! Bitte nachladen.')
            return
        }

        await groupApi.SetAmmoValue(Character.Id, Character.Weapon!.Ammo - 1)
    }

    const onTryReload = async () => {
        const remainingAmmo = Character.Inventory.find(i => i.Item.Name === Items.Munition.Item.Name)?.Amount ?? 0

        if (remainingAmmo <= 0) {
            window.alert('Du hast keine Munition mehr!')
            return
        }

        if (Character.Weapon!.Ammo === Character.Weapon!.MaxAmmo) {
            window.alert('Das Magazin ist schon voll.')
            return
        }

        await groupApi.SetAmmoValue(Character.Id, Character.Weapon?.MaxAmmo ?? 0)
        await groupApi.RemoveItem(Items.Munition.Item.Name, Character.Id)
    }

    return (
        <Card key={'weaponthumbnail-' + Weapon.Name}>
            <Card.Body>
                <Card.Title>{Weapon.Name}</Card.Title>
                <Card.Img src={`/images/${Weapon.ImageName}.png`} />
                <ProgressBar
                    style={{ marginTop: 10, height: 25 }}
                    now={Weapon.Ammo}
                    max={Weapon.MaxAmmo}
                    variant="info"
                />
                <p style={{ textAlign: 'center' }}>{`${Weapon.Ammo}/${Weapon.MaxAmmo} Ladungen`}</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button onClick={() => onTryShoot()} variant="danger">Angriff</Button>
                    <Button onClick={() => onTryReload()} variant="info">Nachladen</Button>
                    <Button onClick={() => groupApi.EquipWeapon(Character.Id, undefined)}>Weglegen</Button>
                </div>
            </Card.Body>
        </Card>
    )
}