import { FC } from "react";
import { CPlanetEntry } from "./CPlanetEntry";
import { EntryUnlocks } from "../../../Classes/CodexState";

export const CCaelumEntry : FC = () => {

    const DescriptionText = <>
        Ein gigantischer Eisplanet mit Ringen, aus einem Gas, die nur im UV-Spectrum sichtbar sind. Bislang wurden noch keine Aufklärungsflüge oder Proben nach Caelum geschickt.
        Es gibt einfach vielversprechendere Planeten...
    </>

    const KnownSpecies : EntryUnlocks[] = []

    const KnownLocations : EntryUnlocks[]  = [
        EntryUnlocks.LocationZuchtlabor
    ]

    return <CPlanetEntry Name="Caelum" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations}/>
}