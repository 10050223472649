import { FC } from "react";
import { Card } from "react-bootstrap";
import { CClickableImage } from "../../Utility/CClickableImage";

export const CSolarSystem: FC = () => {
    
    const planetDataPoints = [
        {X:48.9, Y:81.5, Name:"Hope", Link:"/Codex/Hope"},
        {X:53.1, Y:80.2, Name:"Demeter", Link:"/Codex/Demeter", Left:true},
        {X:38, Y:85, Name:"Hermes", Link:"/Codex/Hermes"},
        {X:10, Y:84, Name:"Poseidon", Link:"/Codex/Poseidon"},
        {X:8, Y:62, Name:"Chronos", Link:"/Codex/Chronos"},
        {X:25, Y:62, Name:"Aphrodite", Link:"/Codex/Aphrodite"},
        {X:25, Y:43, Name:"Hestia", Link:"/Codex/Hestia"},
        {X:19.5, Y:35, Name:"Hephaistos", Link:"/Codex/Hephaistos"},
        {X:26, Y:30, Name:"Dionysos", Link:"/Codex/Dionysos"},
        {X:37.5, Y:49.5, Name:"Hades", Link:"/Codex/Hades", Left:true},
        {X:70, Y:57.5, Name:"Ares", Link:"/Codex/Ares"},
        {X:79, Y:58, Name:"Caelum", Link:"/Codex/Caelum"},
        {X:82, Y:95, Name:"Athene", Link:"/Codex/Athene"},
    ]

    return (
        <Card>
            <Card.Body>
                <Card.Title>Unser Sonnensystem</Card.Title>
                <CClickableImage imgSrc="/images/solarsystem.png" datapoints={ planetDataPoints }/>
                <Card.Text>
                    Die Menschen haben sich ein fülliges Doppelsonnensystem als neue Heimat ausgesucht. Man muss dazu natürlich sagen,
                    dass sie nach fast tausend Jahren suche nicht mehr sonderlich wählerisch waren. Sie ließen sich auf dem am besten bewohnbaren
                    Planeten nieder den sie finden konnten und nannten ihn <a href="/Codex/Hope">Hope</a>. Mitunter dieser Planet würde entscheiden ob sich die Menschheit
                    wieder erholen würde oder für immer aus dem Universum verdrängt werden würde. Bei der Benennung der anderen Himmelskörper,
                    waren die nicht kreativer als in ihrem alten Sonnensystem und benannten sie wieder nach Göttern - dieses Mal jedoch nach den Griechischen.
                    <br/><br/>
                    Die beiden Sonnen Zeus und Hera kreisen im Mittelpunkt des Sonnensystems umeinander und spenden licht und wärme für alle anderen Planeten.
                    Beide sind sie relativ kleine Sonnen, doch Forscher haben berechnet, dass sie noch einige Millionen Jahre Kernfusion betreiben werden, bevor sie erlöschen.
                </Card.Text>
            </Card.Body>
        </Card>
    )
}