import { FC } from "react";
import { EntryUnlocks } from "../../../Classes/CodexState";
import { CPlanetEntry } from "./CPlanetEntry";

export const CAresEntry : FC = () => {

    const DescriptionText = <>
        Ein kleiner mit Vulkanen übersäter Planet. Seine Atmosphäre enthält keinen Sauerstoff und ist unfassbar toxisch für Menschen, was ihm den liebe vollen Spitznamen "Giftzwerg" eigebracht hat.
    </>

    const KnownSpecies : EntryUnlocks[] = []

    const KnownLocations : EntryUnlocks[]  = []

    return <CPlanetEntry Name="Ares" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations}/>
}