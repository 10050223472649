import { FC, useState } from "react";
import { NpcState, Relationship } from "../../Classes/NpcState";
import { GroupApi } from "../../Classes/GroupApi";
import { Button, Card, Form } from "react-bootstrap";
import { CImageSelection } from "../Utility/CImageSelection";
import { CharacterImages } from "../../Classes/CharacterState";

const groupApi = new GroupApi()

export const CNpcCreator: FC = () => {

    const emptyState = {
        Id: '',
        Name: '',
        ImageName: '',
        MaxHealth: 0,
        Health: 0,
        Position: undefined,
        Relationship: Relationship.Unbekannt,
        Active: false
    }

    const [npcState, setNpcState] = useState<NpcState>(emptyState);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setNpcState((prevNpcState) => ({
            ...prevNpcState,
            [name]: value,
        }))
    }

    const handleImageChange = (imageName:string) => {
        setNpcState((prevNpcState) => ({
            ...prevNpcState,
            ImageName: imageName,
        }))
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        npcState.Id = Math.floor(Math.random() * 999999999).toString()
        groupApi.AddNpc(npcState)
        setNpcState(emptyState)
    }

    return (
        <Card style={{ maxWidth: 300, margin:10 }}>
            <Card.Title>Npc Schmiede</Card.Title>
            <Card.Body>
                <form onSubmit={handleSubmit}>
                    <Form.Label style={{ width: '100%' }}>
                        Name:
                        <Form.Control
                            type="text"
                            name="Name"
                            value={npcState.Name}
                            onChange={handleInputChange}
                        />
                    </Form.Label>
                    <Form.Label style={{ width: '100%' }}>
                        Bild:
                        <CImageSelection imageNames={Object.values(CharacterImages)} selected={npcState.ImageName} onChanged={handleImageChange} />
                    </Form.Label>
                    <Form.Label style={{ width: '100%' }}>
                        Trefferpunkte:
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <Form.Control
                                type="number"
                                name="Health"
                                value={npcState.Health}
                                onChange={handleInputChange}
                            />
                            <p>von</p>
                            <Form.Control
                                type="number"
                                name="MaxHealth"
                                value={npcState.MaxHealth}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Label>
                    <Form.Label style={{ width: '100%' }}>
                        Beziehung:
                        <Form.Select
                            name="Relationship"
                            value={npcState.Relationship}
                            onChange={handleInputChange}
                        >
                            <option value={Relationship.Gegner}>Gegner</option>
                            <option value={Relationship.Neutral}>Neutral</option>
                            <option value={Relationship.Freundlich}>Freundlich</option>
                            <option value={Relationship.Unbekannt}>Unbekannt</option>
                        </Form.Select>
                    </Form.Label>
                    <Button style={{ width: '100%' }} type="submit">Erzeugen</Button>
                </form>
            </Card.Body>
        </Card>
    );
}