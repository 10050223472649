import { FC, useState } from "react";
import { GroupApi } from "../../Classes/GroupApi";
import { Items } from "../../Classes/CharacterState";
import { CImageSelection } from "../Utility/CImageSelection";
import { Button, Card, Toast } from "react-bootstrap";

const groupApi = new GroupApi()

export const CItemGifter: FC<{ CharacterId?: string }> = ({ CharacterId }) => {

    const ItemNames = Object.values(Items).map(i => i.Item.Name)
    const [itemName, setItemName] = useState<string | undefined>(undefined)
    const [sending, setSending] = useState<boolean>(false)
    const [showCount, setShowCount] = useState<number>(0)

    const onGiftItem = async () => {
        setSending(true)
        await groupApi.GiveItem(itemName!, CharacterId!)
        setSending(false)
        setShowCount(prev => prev + 1)
        setTimeout(() => setShowCount(prev => Math.max(prev - 1, 0)), 2500)
    }

    return (
        <>
            <Card>
                <Card.Title>Item Gifter</Card.Title>
                <Card.Body>
                    <CImageSelection imageNames={ItemNames} selected={itemName} onChanged={newItem => setItemName(newItem)} selectionBtnText="Item wählen" />
                    <Button disabled={itemName === undefined || CharacterId === undefined || sending} onClick={onGiftItem} style={{ marginLeft: 10 }}>Spieler geben</Button>
                </Card.Body>
            </Card>
            <Toast onClose={() => setShowCount(0)} show={showCount > 0} animation={true}>
                <Toast.Header>
                    <img src={`/images/${itemName}.png`} width={70} height={70} className="rounded mr-2" alt={itemName} />
                    <p>Item wurde versandt ({showCount}).</p>
                </Toast.Header>
            </Toast>
        </>
    )
}