import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { CodexState, EntryUnlocks, KnowsEntry } from "../../../Classes/CodexState";
import { CPlanetEntry } from "./CPlanetEntry";
import { AdditionalCard } from "../../../Classes/AdditionalCard";
import { CodexApi } from "../../../Classes/CodexApi";

export const CDemeterEntry : FC = () => {

    const [context] = useState<CodexState>(CodexApi.FromCache())

    const DescriptionText = <>
    Demeter ist der direkte Nachbarplanet zu <Link to="/Codex/Hope">Hope</Link> sie kreisen beide mit fast genau der gleichen Geschwindigkeit
    um die Sonne uns habe somit ständig einen kleinen Abstand zueinander.
    </>

    const KnownSpecies : EntryUnlocks[] = []

    const KnownLocations : EntryUnlocks[]  = [
        EntryUnlocks.LocationHolyMole
    ]

    const AdditionalCards : AdditionalCard[] = [
        {
            Title:"Beschaffenheit und Mining",
            DescriptionText:<>
                Wegen der vielen dort vorkommenden Ressourcen und der Nähe zu <Link to="/Codex/Hope">Hope</Link> schicken die Menschen dort
                hin immer wieder Expeditionen um seltene Rohstoffe zu bergen. Das ist ein gefährliches Unterfangen...
                viele kleine und große Planetenteile schwirren um Demeter. Diese sind Überbleibsel einer gigantischen Kollision mit einem Gesteinsbrocken.
                Zwar besitzt Demeter eine schwache Atmosphäre, jedoch gibt es dort kaum Sauerstoff oder Wasser und Vegetation.
            </>
        },
        {
            Title:"Besiedlung",
            DescriptionText:<>
                Einige wenige Menschen haben sich auf Demeter Niedergelassen - sie betrachten den kargen Planeten jedoch nicht als ihr Zuhause sondern als
                ihre Arbeitsstelle von der aus sie wichtige Rohstoffe für <Link to="/Codex/Hope">Hope</Link> bergen können. Auch wenn keiner gerne auf
                diesem Planeten ist, sind die Menschen doch froh darüber, welch wichtigen Schätze er bereit hält.
                {KnowsEntry(context, EntryUnlocks.LocationHolyMole) && <>Eine dieser Minenbasen ist <Link to="/Codex/HolyMole">HolyMole</Link>.</>}
            </>
        }
    ]

    return <CPlanetEntry Name="Demeter" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations} AdditionalCards={AdditionalCards}/>
}