import { FC } from "react";
import { EntryUnlocks } from "../../../Classes/CodexState";
import { CPlanetEntry } from "./CPlanetEntry";

export const CPoseidonEntry : FC = () => {
    
    const DescriptionText = <>Der gesamte Planet ist ausnahmslos mit Wasser überdeckt. Wissenschaftler schätzen seine Meere könnten bis zu 50km tief sein und enthalten sehr sicher Leben!</>

    const KnownSpecies : EntryUnlocks[] = [
        EntryUnlocks.CreatureKrangusteln,
        EntryUnlocks.CreatureQuafquaf,
        EntryUnlocks.CreatureKogniQualle
    ]

    const KnownLocations : EntryUnlocks[]  = []

    return <CPlanetEntry Name="Poseidon" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations}/>
}