import { FC } from "react";
import { CCreatureEntry } from "./CCreatureEntry";

export const CBlobEntry : FC = () => {

    const DescriptionText = <>
        Seine schleimige Konsistenz und der unglaubliche Gestank ist mehr als die meisten ertragen. Blobs halten sich meist in feuchten Gebieten aus, da sie Angst haben auszutrocknen.
    </>

    return <CCreatureEntry Name="Blob" DescriptionText={DescriptionText}/>
}