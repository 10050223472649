import { FC } from "react";
import { CItemEntry } from "./CItemEntry";

export const CMedkitEntry : FC = () => {

    const DescriptionText = <>
        Es ist wirklich fantastisch, wie weit die moderne Medizin gekommen ist... Nichts desto trotz ist es besser, wenn man diesen High-Tech-Verbandskasten nicht verwenden muss.
    </>

    return <CItemEntry Name={"Medkit"} DescriptionText={DescriptionText} />
}