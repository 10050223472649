import { FC } from "react";
import { CLocationEntry } from "./CLocationEntry";
import { AdditionalCard } from "../../../Classes/AdditionalCard";
import { KnowledgeKeys } from "../../../Classes/CodexState";
import { Link } from "react-router-dom";

export const CZuchtlaborEntry : FC = () => {

    const DescriptionText = <>
        Auf <Link to="/Codex/Caelum">Caelum</Link> wurde von einem Sateliten eine Struktur geortet, die künstlich angelgt scheint...
    </>

    const BasicInner : AdditionalCard = {
        Title:"Zweck",
        DescriptionText:<>
            Einer der <Link to={"/Codex/Alten"}>Alten</Link> hat sich hier ein Labor eingerichtet um neue Lebewesen zu erschaffen.
        </>,
        ImageName:"Zuchtlabor2.png",
        NeededKnowledgeKeys:[ KnowledgeKeys.ZuchtlaborMeaning ]
    }

    const Maps : AdditionalCard[] = [
        {
            Title:"Zimmer 1",
            DescriptionText:<>
                
            </>,
            ImageName:"ZuchlaborMap1.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.ZuchtlaborMap1 ]
        },
        {
            Title:"Zimmer 2",
            DescriptionText:<>
                
            </>,
            ImageName:"ZuchlaborMap2.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.ZuchtlaborMap2 ]
        },
        {
            Title:"Zimmer 3",
            DescriptionText:<>
                
            </>,
            ImageName:"ZuchlaborMap3.png",
            NeededKnowledgeKeys:[ KnowledgeKeys.ZuchtlaborMap3 ]
        }
    ]

    return <CLocationEntry Name={"Zuchtlabor"} DescriptionText={DescriptionText} AdditionalCards={[ BasicInner, ...Maps ]}/>
}