import { FC } from "react";
import { CharacterState } from "../../Classes/CharacterState";
import { Card, ProgressBar } from "react-bootstrap";
import { CCharacterStats } from "./CCharacterStats";
import { CItemStorage } from "./CItemStorage";
import { CWeaponThumbnail } from "./CWeaponThumbnail";

export const CCharacterOverview: FC<{character:CharacterState, isOwnCharacter?: boolean}> = ({character, isOwnCharacter}) => {

    return (
        <div style={{ marginInline: 15 }} className="wrappable-container">
            <Card>
                <Card.Body>
                    <Card.Title>{character.Name}</Card.Title>
                    <Card.Img src={`/images/${character.Name}.png`} />
                    <ProgressBar
                        style={{ marginTop:10, height:25 }}
                        now={character.Stats.Health}
                        max={character.Stats.MaxHealth}
                        variant="danger"
                        label={`${character.Stats.Health} HP`}
                    />
                    <CCharacterStats character={character} />
                    {character.Weapon !== undefined && <CWeaponThumbnail Weapon={character.Weapon} Character={character}/>}
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Card.Title>Inventar</Card.Title>
                    <CItemStorage items={character.Inventory} isOwnStorage={isOwnCharacter} ownerId={character.Id}/>
                </Card.Body>
            </Card>
        </div>
    )
}