import { FC } from "react"
import { CItemEntry } from "./CItemEntry"
import { Items } from "../../../Classes/CharacterState"

export const CBulkSNEntry : FC = () => {
    const DescriptionText = <>
        Das schwere Scharfschützengewehr hat eine hohe Reichweite und Schaden, ist jedoch verhältnissmäßig unhandlich.<br/>
        <b>Schaden:</b> W20<br/>
        <b>Magazingröße:</b> {Items.BulkSN.Weapon.MaxAmmo}<br/>
        Wenn sich das Ziel bewegt -5 auf den Trefferwurf. Wenn der Schütze angegriffen wird weitere -5 auf den Trefferwurf.
    </>

    return <CItemEntry Name={Items.BulkSN.Item.Name} DescriptionText={DescriptionText} />
}