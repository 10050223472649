import { Card } from "react-bootstrap"
import { CodexState, KnowledgeKeys, Knows } from "./CodexState";
import { useState } from "react";
import { CodexApi } from "./CodexApi";

export interface AdditionalCard {
    Title:string,
    ImageName?:string,
    DescriptionText:JSX.Element,
    NeededKnowledgeKeys?:KnowledgeKeys[]
}

export const AdditionalToElement = (ac:AdditionalCard) : JSX.Element  => {
    
    const [context] = useState<CodexState>(CodexApi.FromCache())

    const allKnown = (ac.NeededKnowledgeKeys ?? []).every(kk => Knows(context, kk))
    
    if(!allKnown){
        return <></>
    }
    
    return <Card key={ac.Title}>
                <Card.Body>
                    <Card.Title>{ac.Title}</Card.Title>
                    {ac.ImageName && <Card.Img src={`/images/${ac.ImageName}`}/>}
                    <Card.Text>
                        {ac.DescriptionText}
                    </Card.Text>
                </Card.Body>
            </Card>
}