import { CBulkSNEntry } from "../Components/Codex/Items/CBulkSNEntry"
import { CCChainsawEntry } from "../Components/Codex/Items/CCChainsawEntry"
import { CHelperBotEntry } from "../Components/Codex/Items/CHelperBotEntry"
import { CMedkitEntry } from "../Components/Codex/Items/CMedkitEntry"
import { CP2000NEntry } from "../Components/Codex/Items/CP2000Entry"
import { MapCoordinate } from "./GroupState"
import { WeaponState } from "./WeaponState"

export interface CharacterState {
    Id: string
    Stats: Stats,
    Inventory: { Item: Item, Amount: number }[],
    Weapon?: WeaponState,
    Name: string,
    Position?: MapCoordinate,
}

export interface Stats {
    Strength: number,
    Intelligence: number,
    Dexterity: number,
    MaxHealth: number,
    Health: number
}

export interface Item {
    Name: string,
    Unhandiness: number
}

const Medkit: Item = { Name: "Medkit", Unhandiness: 10 }
const HelperBot: Item = { Name: "HelperBot", Unhandiness: 50 }
const Munition: Item = { Name: "Munition", Unhandiness: 3 }
const BulkSN: Item = { Name: "BulkSN", Unhandiness: 70 }
const BulkSNWeapon: WeaponState = { Name: BulkSN.Name, Ammo: 0, MaxAmmo: 1, ImageName: BulkSN.Name }
const P2000: Item = { Name: "P2000", Unhandiness: 20 }
const P2000Weapon: WeaponState = { Name: P2000.Name, Ammo: 0, MaxAmmo: 6, ImageName: P2000.Name }
const CCChainsaw: Item = { Name: "CCChainsaw", Unhandiness: 40 }
const CCChainsawWeapon: WeaponState = { Name: CCChainsaw.Name, Ammo: 0, MaxAmmo: 3, ImageName: CCChainsaw.Name }

export const Items = {
    Medkit: { Item: Medkit, Description: <CMedkitEntry /> },
    HelperBot: { Item: HelperBot, Description: <CHelperBotEntry /> },
    Munition: { Item: Munition, Description: <>Es ist Munition...</> },
    BulkSN: { Item: BulkSN, Description: <CBulkSNEntry />, Weapon: BulkSNWeapon },
    P2000: { Item: P2000, Description: <CP2000NEntry />, Weapon: P2000Weapon },
    CCChainsaw: { Item: CCChainsaw, Description: <CCChainsawEntry />, Weapon: CCChainsawWeapon }
}

export const CharacterImages = {
    Helena: "Helena",
    Quinn: "Quinn",
    Vera: "Vera",
    Mekhai: "Mekhai",
    Berry: "Berry",
    Simon: "Simon",
    Derek: "Derek",
    Owen: "Owen",
    Joe: "Joe",
    Blob: "Blob",
    Krangusteln: "Krangusteln",
    Wasserfuchs: "Wasserfuchs",
    SolarSerpent: "SolarSerpent",
    Guardians: "Guardians",
    NebulaRaptor: "NebulaRaptor",
    MagnetiteBehemoth: "MagnetiteBehemoth",
    Dweller: "Dweller",
    Stalker: "Stalker",
}