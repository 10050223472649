import { FC } from "react"
import { CItemEntry } from "./CItemEntry"
import { Items } from "../../../Classes/CharacterState"

export const CP2000NEntry : FC = () => {
    const DescriptionText = <>
        Eine kleine und portable Handfeuerwaffe.
        <b>Schaden:</b> W6<br/>
        <b>Magazingröße:</b> {Items.P2000.Weapon.MaxAmmo}<br/>
    </>

    return <CItemEntry Name={Items.P2000.Item.Name} DescriptionText={DescriptionText} />
}