import { FC } from "react";
import { CharacterState } from "../../Classes/CharacterState";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faHandFist, faHeart, faTachometerAltFast } from "@fortawesome/free-solid-svg-icons";

export const CCharacterStats : FC<{character:CharacterState}> = ({character}) => (
    <Table>
        <thead>
            <tr>
                <th colSpan={2}>Stats</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><FontAwesomeIcon icon={faHeart}/> Trefferpunkte</td>
                <td>{character.Stats.MaxHealth}</td>
            </tr>
            <tr>
                <td><FontAwesomeIcon icon={faBrain}/> Intelligenz</td>
                <td>{character.Stats.Intelligence}</td>
            </tr>
            <tr>
                <td><FontAwesomeIcon icon={faTachometerAltFast}/> Geschwindigkeit</td>
                <td>{character.Stats.Dexterity}</td>
            </tr>
            <tr>
                <td><FontAwesomeIcon icon={faHandFist}/> Stärke</td>
                <td>{character.Stats.Strength}</td>
            </tr>
        </tbody>
    </Table>
)