import { MapCoordinate } from "./GroupState";

export enum Relationship {
    Freundlich,
    Unbekannt,
    Gegner,
    Neutral
}

export interface NpcState {
    Id:string,
    Name:string,
    ImageName:string,
    MaxHealth:number,
    Health:number,
    Position?:MapCoordinate,
    Relationship:Relationship,
    Active:boolean
}