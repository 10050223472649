import { FC } from "react";
import { Spinner } from "react-bootstrap";

export const CLoading: FC<{ Message?: string, ImageName?: string }> = ({ Message, ImageName }) => {
    return (
        <div style={{ marginInline: 15, marginBlock: 20 }} className="wrappable-container">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10 }}>
                <Spinner />
                <p>{Message}</p>
                {ImageName !== undefined && <img src={`/images/${ImageName}.png`} width={100} height={100} alt={ImageName}/>}
            </div>
        </div>
    )
} 