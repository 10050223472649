import { FC } from "react";
import { Card } from "react-bootstrap";
import { AdditionalCard, AdditionalToElement } from "../../../Classes/AdditionalCard";


export interface LocationDetails {
    Name:string,
    DescriptionText:JSX.Element,
    AdditionalCards?:AdditionalCard[],
}

export const CLocationEntry : FC<LocationDetails> = ({ Name, DescriptionText, AdditionalCards }) => {

    return <>
        <Card>
            <Card.Body>
                <Card.Title>{Name}</Card.Title>
                <Card.Img src={`/images/${Name}.png`}/>
                <Card.Text>
                    {DescriptionText}
                </Card.Text>
            </Card.Body>
        </Card>
        {AdditionalCards && AdditionalCards.map(AdditionalToElement)}
    </>
}