import { FC, useEffect, useRef, useState } from "react";
import { CNpcCreator } from "./CNpcCreator";
import { CGroupMemberSelectionDialog } from "../Character/CGroupMemberSelectionDialog";
import { GroupState } from "../../Classes/GroupState";
import { GroupApi, RefreshRate } from "../../Classes/GroupApi";
import { CLoading } from "../Utility/CLoading";
import { CNpcSelectionDialog } from "./CNpcSelectionDialog";
import { CGroupMap } from "../Character/CGroupMap";
import { Button, Form } from "react-bootstrap";
import { CItemGifter } from "./CItemGifter";
import { CMapManagement } from "./CMapManagement";

const groupApi = new GroupApi()
const controller = new AbortController()

export const CDmView : FC = () => {

    const [groupState, setGroupState] = useState<GroupState | undefined>(undefined);
    const [selectedCharacterId, setSelectedCharacterId] = useState<string>("");
    const healTbx = useRef<HTMLInputElement|null>(null);
    
    useEffect(() => {
        const signal = controller.signal
        const timer = setInterval(()=>groupApi.GetGroup(signal).then(group => setGroupState(group)), RefreshRate)
        
        return () => {
            clearInterval(timer)
            controller.abort()
        }
    }, [])

    const deleteNpc = ()=>{
        if(window.confirm('Willst du den NPC sicher löschen?')){
            groupApi.RemoveNpc(selectedCharacterId)
        }
    }

    if (groupState === undefined) {
        return <CLoading Message="Funkt die Gruppe an..." />
    }
    
    return (
        <>
            <div style={{ marginInline: 15, marginBottom: 20 }} className="wrappable-container">
                <CNpcCreator/>
                <div style={{display:'flex', flexDirection:'column', justifyContent: 'center', gap:20}}>
                    <Form.Control ref={healTbx} type="text" placeholder="Health"/>
                    <Button onClick={()=>groupApi.AddHealth(+(healTbx.current?.value ?? '0'), selectedCharacterId)}>Add Health</Button><br/>
                    <Button disabled={groupState.NpcStates.every(npc => npc.Id !== selectedCharacterId)} onClick={()=>groupApi.ToggleActiveNpc(selectedCharacterId)}>Toggle NPC Active</Button>
                    <Button disabled={groupState.NpcStates.every(npc => npc.Id !== selectedCharacterId)} onClick={deleteNpc}>Delete NPC</Button>
                    <Button onClick={()=>groupApi.SetPosition(undefined, selectedCharacterId)}>Remove from Map</Button>
                    <CItemGifter CharacterId={groupState.GroupMembers.every(char => char.Id !== selectedCharacterId) ? undefined : selectedCharacterId}/>
                </div>
                <CMapManagement Maps={groupState.Maps}/>
            </div>
            <CGroupMemberSelectionDialog selectedId={selectedCharacterId} groupMembers={groupState.GroupMembers} onSelected={cId=>setSelectedCharacterId(cId)}/>
            <CNpcSelectionDialog selectedId={selectedCharacterId} npcs={groupState.NpcStates} onSelected={cId=>setSelectedCharacterId(cId)} />
            <CGroupMap DmMode={true} GroupState={groupState} OwnCharacterId={selectedCharacterId} />
        </>
    )
}