import { FC } from "react";
import { Link } from "react-router-dom";
import { EntryUnlocks } from "../../../Classes/CodexState";
import { CPlanetEntry } from "./CPlanetEntry";

export const CAphroditeEntry : FC = () => {

    const DescriptionText = <>
        Noch wurden keine Proben zu dem sich relativ schnell bewegenden pinken Planeten geschickt. Wissenschaftler vermuten nach ersten Spektroskopien,
        ein bislang unbekanntes Gas auf dem Planeten, welches die charakteristische Pinkfärbung erzeugen soll.<br/>
        <br/>
        Von Zeit zu Zeit ist der Planet sogar mit bloßem Auge von <Link to="/Codex/Hope">Hope</Link> aus zu sehen und huscht dann wie eine Sternschnuppe
        über den Nachthimmel. Vermutlich auch deswegen ist er der Planet ein Glückssymbol für Liebende.
    </>

    const KnownSpecies : EntryUnlocks[] = []

    const KnownLocations : EntryUnlocks[]  = []

    return <CPlanetEntry Name="Aphrodite" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations}/>
}