import { FC } from "react";
import { Card } from "react-bootstrap";

export const CCodexHome : FC = () => {

    return <>
        <Card>
            <Card.Body>
                <Card.Title>Der Codex</Card.Title>
                <Card.Img src="/images/CodexLogo.png"/>
                <Card.Text>
                    Was möchtest du gerne wissen?
                </Card.Text>
            </Card.Body>
        </Card>
    </>
}