import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useState } from "react";
import './CClickableImage.css'
import { Coordintate } from "../../Classes/GroupState";

export interface DataPoint {
    X: number,
    Y: number,
    Link?: string,
    Name: string,
    Left?: boolean,
    Color?: string,
    ImageName?:string
}

const getLeft = (dp: DataPoint) => dp.Link === undefined ? (
    <p className="clickable-image-lbl" key={`${dp.X}|${dp.Y}`} style={{ position: 'absolute', right: `${dp.X}%`, bottom: `${dp.Y}%`, color: dp.Color ?? 'white' }}>
        {dp.Name}<FontAwesomeIcon style={{ paddingLeft: 3 }} icon={faLocationPin} />
    </p>
) : (
    <a className="clickable-image-lbl" href={dp.Link} key={`${dp.X}|${dp.Y}`} style={{ position: 'absolute', right: `${dp.X}%`, bottom: `${dp.Y}%`, color: dp.Color ?? 'white' }}>
        {dp.Name}<FontAwesomeIcon style={{ paddingLeft: 3 }} icon={faLocationPin} />
    </a>
)

const getRight = (dp: DataPoint) => dp.Link === undefined ? (
    <p className="clickable-image-lbl" key={`${dp.X}|${dp.Y}`} style={{ position: 'absolute', left: `${dp.X}%`, bottom: `${dp.Y}%`, color: dp.Color ?? 'white' }}>
        <FontAwesomeIcon style={{ paddingRight: 3 }} icon={faLocationPin} />{dp.Name}
    </p>
) : (
    <a className="clickable-image-lbl" href={dp.Link} key={`${dp.X}|${dp.Y}`} style={{ position: 'absolute', left: `${dp.X}%`, bottom: `${dp.Y}%`, color: dp.Color ?? 'white' }}>
        <FontAwesomeIcon style={{ paddingRight: 3 }} icon={faLocationPin} />{dp.Name}
    </a>
)

const getImage = (dp: DataPoint, size:number) => (
    <div key={'map-icon-'+dp.Name+dp.X+dp.Y} style={{ position: 'absolute', left: `${dp.X}%`, bottom: `${dp.Y}%`, color: dp.Color ?? 'white', width:size+2, height:size+2, padding:1, backgroundColor:'white' }}>
        <img src={`/images/${dp.ImageName}.png`} alt={dp.ImageName} width={size} height={size} style={{display: 'block'}}/>
    </div>
)

export const CClickableImage: FC<{ imgSrc: string, datapoints: DataPoint[], onClick?: (pos: Coordintate) => void }> = ({ imgSrc, datapoints, onClick }) => {

    const [size, setSize] = useState<number|undefined>()

    const onC = (e: React.MouseEvent<HTMLImageElement>) => {
        if (onClick === undefined) return;

        const rect = (e.target as any).getBoundingClientRect()
        const x = (e.clientX - rect.x) / rect.width
        const y = 1 - ((e.clientY - rect.y) / rect.height)
        onClick({ X: x * 100 - 0.5, Y: y * 100 - 2 })
    }

    const onLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const rect = (e.target as any).getBoundingClientRect()
        setSize(Math.floor(rect.width/17))
    }
    
    return (
        <div style={{ position: 'relative' }}>
            <img onClick={onC} style={{ width: '100%' }} onLoad={onLoaded} src={imgSrc} alt={imgSrc} />
            {size !== undefined ? (datapoints.map(dp => {
                if(dp.ImageName !== undefined) return getImage(dp, size)

                return dp.Left ? getLeft(dp) : getRight(dp)
            })) : null }
        </div>
    )
}