import { FC } from "react";
import { Card, ProgressBar } from "react-bootstrap";
import { NpcState, Relationship } from "../../Classes/NpcState";

export const CNpcThumbnail: FC<{ npc: NpcState, onClick?: () => void, width: number, selected?:boolean, hideHealthBarOnEnemy?:boolean }> = ({ npc, onClick, width, selected, hideHealthBarOnEnemy }) => {
    
    const showHealth = (!(hideHealthBarOnEnemy ?? false) || npc.Relationship.toString() !== Relationship.Gegner.toString())

    return (
        <Card onClick={onClick} style={{ width: width, height: width + 80 }} bg={selected ? 'warning' : ''}>
            <Card.Body>
                <Card.Title>{npc.Name}</Card.Title>
                <Card.Img src={`/images/${npc.ImageName}.png`} style={{filter:npc.Active ? '' : 'grayscale(100%)'}} />
                {showHealth && <ProgressBar
                    style={{ marginTop: 10, height: 25 }}
                    now={npc.Health}
                    max={npc.MaxHealth}
                    variant="danger"
                    label={`${npc.Health} HP`}
                />}
                <p style={{textAlign:'center'}}>{Relationship[npc.Relationship]}</p>
            </Card.Body>
        </Card>
    )
}