import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CCodex } from "./Components/Codex/CCodex";
import './main.scss';
import { CSolarSystem as CSolarSystemEntry } from "./Components/Codex/Solarsystem/CSolarSystem";
import { CHopeEntry } from "./Components/Codex/Solarsystem/CHopeEntry";
import { CAresEntry } from "./Components/Codex/Solarsystem/CAresEntry";
import { CWaterfoxEntry } from "./Components/Codex/Creatures/CWaterfoxEntry";
import { CCodexHome } from "./Components/Codex/CCodexHome";
import { CDemeterEntry } from "./Components/Codex/Solarsystem/CDemeterEntry";
import { CHolyMoleEntry } from "./Components/Codex/Locations.tsx/CHolyMoleEntry";
import { CHadesEntry } from "./Components/Codex/Solarsystem/CHadesEntry";
import { CPoseidonEntry } from "./Components/Codex/Solarsystem/CPoseidonEntry";
import { CKrangustelnEntry } from "./Components/Codex/Creatures/CKrangustelnEntry";
import { CKogniQualleEntry } from "./Components/Codex/Creatures/CKogniQualleEntry";
import { CAphroditeEntry } from "./Components/Codex/Solarsystem/CAphroditeEntry";
import { CCaelumEntry } from "./Components/Codex/Solarsystem/CCaelumEntry";
import { CQuafquafEntry } from "./Components/Codex/Creatures/CQuafqaufEntry";
import { CZuchtlaborEntry } from "./Components/Codex/Locations.tsx/CZuchtlaborEntry";
import { CEcolibriumEntry } from "./Components/Codex/Locations.tsx/CEcolibriumEntry";
import { CAltenEntry } from "./Components/Codex/Creatures/CAltenEntry";
import { CBlobEntry } from "./Components/Codex/Creatures/CBlobEntry";
import { CSchwererRaumanzugEntry } from "./Components/Codex/Items/CSchwererRaumanzugEntry";
import { CMedkitEntry } from "./Components/Codex/Items/CMedkitEntry";
import { CHelperBotEntry } from "./Components/Codex/Items/CHelperBotEntry";
import { FC, useEffect } from "react";
import { CodexApi } from "./Classes/CodexApi";
import { CGroupOverview } from "./Components/Character/CGroupOverview";
import { CDmView } from "./Components/DungeonMaster/CDmView";

const codexApi = new CodexApi()

export const App : FC = () => {

  useEffect(() => {
    codexApi.GetCodex()
  }, [])

  const PlanetRoutes = <>
    <Route path="Hope" Component={CHopeEntry} />
    <Route path="Ares" Component={CAresEntry} />
    <Route path="Caelum" Component={CCaelumEntry} />
    <Route path="Hades" Component={CHadesEntry} />
    <Route path="Poseidon" Component={CPoseidonEntry} />
    <Route path="Aphrodite" Component={CAphroditeEntry} />
    <Route path="Demeter" Component={CDemeterEntry} />
  </>

  const LocationRoutes = <>
    <Route path="HolyMole" Component={CHolyMoleEntry} />
    <Route path="Zuchtlabor" Component={CZuchtlaborEntry} />
    <Route path="Ecolibrium" Component={CEcolibriumEntry} />
  </>

  const CreatureRoutes = <>
    <Route path="Wasserfuchs" Component={CWaterfoxEntry} />
    <Route path="Krangusteln" Component={CKrangustelnEntry} />
    <Route path="Quafquaf" Component={CQuafquafEntry} />
    <Route path="KogniQualle" Component={CKogniQualleEntry} />
    <Route path="Alten" Component={CAltenEntry} />
    <Route path="Blob" Component={CBlobEntry} />
  </>

  const ItemRoutes = <>
    <Route path="SchwererRaumanzug" Component={CSchwererRaumanzugEntry} />
    <Route path="Medkit" Component={CMedkitEntry} />
    <Route path="HelperBot" Component={CHelperBotEntry} />
  </>

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/Codex" Component={CCodex}>
            <Route path="Home" Component={CCodexHome} />
            <Route path="SolarSystem" Component={CSolarSystemEntry} />
            {PlanetRoutes}
            {LocationRoutes}
            {CreatureRoutes}
            {ItemRoutes}
            <Route path="*" element={<p>404 Entry</p>} />
          </Route>
          <Route path="/Group" element={<CGroupOverview OwnCharacterId="0"/>} />
          <Route path="/DM" Component={CDmView} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(<App />);