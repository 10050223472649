import { FC } from "react";
import { Card } from "react-bootstrap";
import { EntryUnlocks } from "../../../Classes/CodexState";
import { CPlanetEntry } from "./CPlanetEntry";

export const CHopeEntry : FC = () => {
    
    const DescriptionText = <>
    Der neue Heimatplanet der Menschen mit einer angenehmen Athmosphäre und Themperaturen. Nach der Ankunft in diesem Sonnensystem
    haben die Menschen angefangen ihre Zivilisation wieder aufzubauen.
    <Card.Img src="/images/Hope2.png"/>
    </>

    const KnownSpecies : EntryUnlocks[] = [
        EntryUnlocks.CreatureWasserfuchs
    ]

    const KnownLocations : EntryUnlocks[]  = [
        EntryUnlocks.LocationEcolibrium
    ]

    return <CPlanetEntry Name="Hope" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations}/>
}