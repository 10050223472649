import { FC, useState } from "react";
import { Map, MapNames } from "../../Classes/GroupState";
import { Button, Card, Form } from "react-bootstrap";
import { GroupApi } from "../../Classes/GroupApi";
import { CImageSelection } from "../Utility/CImageSelection";

const groupApi = new GroupApi()

export const CMapManagement: FC<{ Maps: Map[] }> = ({ Maps }) => {
    
    const [selectedMapImage, setSelectedMapImage] = useState<string|undefined>(undefined)
    const [selectedMapName, setSelectedMapName] = useState<string>("")

    const onCreate = async () => {
        await groupApi.AddMap(selectedMapName, selectedMapImage!)
        setSelectedMapImage(undefined)
        setSelectedMapName("")
    }

    return (
        <Card key="map-manager" style={{ maxWidth: 250 }}>
            <Card.Title>Mapmanager</Card.Title>
            <Card.Body style={{display:'flex', gap:10, flexDirection: 'column'}}>
                {Maps.map(m =>
                    <Card key={"map-manager-" + m.Id}>
                        <Card.Title>{m.MapName}</Card.Title>
                        <Card.Body style={{padding:10,display:'flex', gap:10, flexDirection: 'column'}}>
                            <Button variant={m.Active ? 'outline-danger' : 'outline-success'} onClick={() => groupApi.SetMapActive(m.Id, !m.Active)}>{m.Active ? 'Deaktivieren' : 'Aktivieren'}</Button>
                            <Button variant="danger" onClick={() => groupApi.RemoveMap(m.Id)}>Entfernen</Button>
                        </Card.Body>
                    </Card>
                )}
            </Card.Body>
            <h4>Karte erstellen</h4>
            <CImageSelection imageNames={MapNames} onChanged={mapName => {setSelectedMapImage(mapName);setSelectedMapName(mapName)}} selected={selectedMapImage} selectionBtnText="Karte wählen" />
            <Form.Control placeholder="Kartenname" type="text" value={selectedMapName} onChange={e => setSelectedMapName(e.target.value)}/>
            {selectedMapImage !== undefined ? <Button onClick={onCreate}>Karte erstellen</Button>: null}
        </Card>
    )
}