import { FC } from "react";
import { CItemEntry } from "./CItemEntry";

export const CSchwererRaumanzugEntry : FC = () => {

    const DescriptionText = <>
        Dieser Anzug bietet Schutz gegen alles, was einem im Weltraum unangenehm werden könnte. Auch seine gute Konfigurierbarkeit spricht für das Modell.
        Leider gibt es nur wenige dieser Anzüge und die, die sie haben, wollen sie aus offensichtlichen Gründen nicht hergeben.
    </>

    return <CItemEntry Name={"Schwerer Raumanzug"} DescriptionText={DescriptionText} />
}