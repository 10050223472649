import { FC } from "react";
import { CCreatureEntry } from "./CCreatureEntry";

export const CAltenEntry : FC = () => {

    const DescriptionText = <>
        Eine alte und mächtige Zivilisation die sich in den Sternen ausgebreitet hat. Sie haben es sich zur Aufgabe gemacht das gesamte Weltall mit Leben zu füllen.
        Mit einzigartigen und aufeinander abgestimmten Lebewesen, die eine stabile Symbiose eingehen.
        Sie fungieren oft als Wächter eines Sonnensystems und schaffen dort neues Leben.
    </>

    return <CCreatureEntry Name="Alten" DescriptionText={DescriptionText}/>
}