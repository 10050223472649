import { FC } from "react";
import { Link } from "react-router-dom";
import { CCreatureEntry } from "./CCreatureEntry";

export const CWaterfoxEntry : FC = () => {

    const DescriptionText = <>
        Ein auf <Link to="/Codex/Hope">Hope</Link> vorkommendes Wesen, welches Wasser beeinflussen kann. Es ist sehr scheu, kann aber mit äußerster Vorsicht und etwas Fisch befreundet werden.
    </>

    return <CCreatureEntry Name="Wasserfuchs" DescriptionText={DescriptionText}/>
}