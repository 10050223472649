import { FC } from "react";
import { CPlanetEntry } from "./CPlanetEntry";
import { EntryUnlocks } from "../../../Classes/CodexState";

export const CHadesEntry : FC = () => {

    const DescriptionText = <>
    Hades ist auf der Oberfläche einer der Unscheinbarsten Planeten. Man sollte sich davon aber nicht täuschen lassen.
    Seismographische Messungen ergaben, dass der Planet auf den ersten zehn Kilometern unter der Erde einem Schweizerkäse gleicht.
    Unzählige Tunnel mit vielleicht verborgenen schätzen schlummern dort.
    </>

    const KnownSpecies : EntryUnlocks[] = [
        EntryUnlocks.CreatureBlob
    ]

    const KnownLocations : EntryUnlocks[]  = []

    return <CPlanetEntry Name="Hades" DescriptionText={DescriptionText} KnownSpecies={KnownSpecies} KnownLocations={KnownLocations}/>
}