import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { CodexState, EntryUnlocks, KnowsEntry } from "../../../Classes/CodexState";
import { CCreatureEntry } from "./CCreatureEntry";
import { CodexApi } from "../../../Classes/CodexApi";

export const CKrangustelnEntry : FC = () => {

    const [context] = useState<CodexState>(CodexApi.FromCache())

    const DescriptionText = <>
        Sie als intelligent zu bezeichnen ist vielleicht etwas zu viel der guten Worte, doch immer hin scheinen
        sie über eine ausgeprägte Sprache zu verfügen. Sie leben ausschließlich am Meeresgrund von <Link to="/Codex/Poseidon">Poseidon</Link>,
        wo sie kleine Dörfer bilden. Diese Herrschaften sind sehr leicht reizbar und haben eine äußerst dicke Panzerung.<br/>
        {KnowsEntry(context, EntryUnlocks.CreatureQuafquaf) && <>
            <br/>Die Krangusteln und die <Link to="/Codex/Quafquaf">Quafquaf</Link> können sich jedoch überhaupt nicht leiden und bekämpfen sich von Zeit zu Zeit.
        </>}
    </>

    return <CCreatureEntry Name="Krangusteln" DescriptionText={DescriptionText}/>
}