import { FC } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

export const CCodex: FC = () => {
    return (
        <div style={{marginInline:15}}>
            <Navbar expand="lg" className="navbar">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-nav">
                        <Nav.Link href="/Codex/Solarsystem" as="a" className="nav-link"><Button>Unser Sonnensystem</Button></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Link to="/Codex/Home"><h1>Codex</h1></Link>
            </Navbar>
            <div className="wrappable-container">
                <Outlet/>
            </div>
        </div>
    )
}