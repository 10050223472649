import { FC } from "react"
import { CItemEntry } from "./CItemEntry"
import { Items } from "../../../Classes/CharacterState"

export const CCChainsawEntry : FC = () => {
    const DescriptionText = <>
        Wer wollte noch nicht mit einer Kettensäge bewaffnet durch die Gegend rennen...?<br/>
        Bei normalen Angriffen verbraucht diese Waffe keine Munition und verursacht moderaten Schaden:<br/>
        <b>Schaden:</b> W6<br/>
        Es ist jedoch auch möglich den Turbo anzuschalten! Das "feuern" der Waffe bewirkt, dass beim nächste nächste Angriff sowohl der
        Trefferwurf als auch der Schadenswurf doppelt geworfen werden dürfen. Es zählt dann jeweils der höhere Wurf. 
        <b>Magazingröße:</b> {Items.CCChainsaw.Weapon.MaxAmmo}<br/>
    </>

    return <CItemEntry Name={Items.CCChainsaw.Item.Name} DescriptionText={DescriptionText} />
}