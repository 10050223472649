import { CharacterState, Items } from "./CharacterState"
import { GroupState, MapCoordinate } from "./GroupState"
import { NpcState } from "./NpcState"
import { WeaponState } from "./WeaponState"
import { Map } from "../Classes/GroupState";

export const RefreshRate = 100

export class GroupApi {
    
    public SetMapActive(MapId: string, Active: boolean) : Promise<GroupState>  {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const map = g.Maps.find(m => m.Id === MapId)

            if(map !== undefined){
                const newG = { ...g, Maps: [...g.Maps.filter(m => m.Id !== MapId), { ...map, Active: Active }] }
                SaveGroupToStorage(newG)
            }
            
            res(await this.GetGroup(null))
        })
    }

    public AddMap(MapName: string, MapImage:string) : Promise<GroupState>  {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const map : Map = {MapName:MapName, MapImageName:MapImage, Active:false, Id:Math.floor(Math.random()*99999).toString()}

            const newG = { ...g, Maps: [...g.Maps, map] }
            SaveGroupToStorage(newG)
            
            res(await this.GetGroup(null))
        })
    }

    public RemoveMap(MapId: string) : Promise<GroupState>  {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            
            const newG = { ...g, Maps: g.Maps.filter(m => m.Id !== MapId) }
            SaveGroupToStorage(newG)
            
            res(await this.GetGroup(null))
        })
    }

    public RemoveItem(ItemName: string, CharacterId: string): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const member = g.GroupMembers.find(c => c.Id === CharacterId)!
            const indexOfFirst = member.Inventory.findIndex(i => i.Item.Name === ItemName)
            
            const newInv = [...member.Inventory]
            if(member.Inventory[indexOfFirst].Amount === 1){
                newInv.splice(indexOfFirst, 1)
            }
            member.Inventory[indexOfFirst].Amount--
            
            const newG = { ...g, GroupMembers: [...g.GroupMembers.filter(c => c.Id !== CharacterId), { ...member, Inventory: newInv }] }
            SaveGroupToStorage(newG)
            res(newG)
        })
    }

    public GiveItem(ItemName: string, CharacterId: string): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const member = g.GroupMembers.find(c => c.Id === CharacterId)!
            const item = member.Inventory.find(i => i.Item.Name === ItemName)
            const newInv = [...member.Inventory]
            
            if(item === undefined){
                const newItem = Object.values(Items).find(i => i.Item.Name === ItemName)
                newInv.push({Item:newItem!.Item, Amount:1})
            }else{
                item.Amount++
            }
            
            const newG = { ...g, GroupMembers: [...g.GroupMembers.filter(c => c.Id !== CharacterId), { ...member, Inventory: newInv }] }
            SaveGroupToStorage(newG)
            res(newG)
        })
    }

    public SetAmmoValue(CharacterId: string, AmmoValue:number): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const member = g.GroupMembers.find(c => c.Id === CharacterId)!
            const weapon = member.Weapon

            if(weapon !== undefined){
                const newWeapon = {...weapon, Ammo:AmmoValue }
                
                const newG = { ...g, GroupMembers: [...g.GroupMembers.filter(c => c.Id !== CharacterId), { ...member, Weapon: newWeapon }] }
                SaveGroupToStorage(newG)
            }

            res(await this.GetGroup(null))
        })
    }

    public GetGroup(signal: AbortSignal | null): Promise<GroupState> {
        return new Promise<GroupState>((res, rej) => {
            const result = GetGroupFromStorage()
            result.GroupMembers.sort((a, b) => a.Id.localeCompare(b.Id))
            result.NpcStates.sort((a, b) => a.Id.localeCompare(b.Id))
            res(result)
        })
    }

    public AddNpc(newNpc: NpcState): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)

            const newG = { ...g, NpcStates: [...g.NpcStates, newNpc] }
            SaveGroupToStorage(newG)

            res(await this.GetGroup(null))
        })
    }

    public ToggleActiveNpc(npcId: string): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const npc = g.NpcStates.find(npc => npc.Id === npcId)

            if (npc !== undefined) {
                const newG = { ...g, NpcStates: [...g.NpcStates.filter(npc => npc.Id !== npcId), { ...npc, Active: !npc.Active }] }
                SaveGroupToStorage(newG)
            }

            res(await this.GetGroup(null))
        })
    }

    public RemoveNpc(npcId: string): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)

            const newG = { ...g, NpcStates: g.NpcStates.filter(npc => npc.Id !== npcId) }
            SaveGroupToStorage(newG)

            res(await this.GetGroup(null))
        })
    }

    public EquipWeapon(characterId: string, Weapon:WeaponState|undefined): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const char = g.GroupMembers.find(c => c.Id === characterId)!

            const newG = { ...g, GroupMembers:[...g.GroupMembers.filter(c => c.Id !== characterId), { ...char, Weapon: Weapon }] }
            SaveGroupToStorage(newG)

            res(await this.GetGroup(null))
        })
    }

    public AddHealth(Health: number, CharacterId: string): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const member = g.GroupMembers.find(c => c.Id === CharacterId)
            const npc = g.NpcStates.find(c => c.Id === CharacterId)
            if (member !== undefined) {
                const newStats = { ...member.Stats, Health: Math.max(Math.min(member.Stats.MaxHealth, member.Stats.Health + Health), 0) }
                const newG = { ...g, GroupMembers: [...g.GroupMembers.filter(c => c.Id !== CharacterId), { ...member, Stats: newStats }] }
                SaveGroupToStorage(newG)
            }

            if (npc !== undefined) {
                const newStats = { ...npc, Health: Math.max(Math.min(npc.MaxHealth, npc.Health + Health), 0) }
                const newG = { ...g, NpcStates: [...g.NpcStates.filter(c => c.Id !== CharacterId), newStats] }
                SaveGroupToStorage(newG)
            }
            
            res(await this.GetGroup(null))
        })
    }

    public SetPosition(newPosition: MapCoordinate|undefined, entityId: string): Promise<GroupState> {
        return new Promise<GroupState>(async (res, rej) => {
            const g = await this.GetGroup(null)
            const char = g.GroupMembers.find(c => c.Id === entityId)
            const npc = g.NpcStates.find(c => c.Id === entityId)

            if (char !== undefined) {
                const newG = { ...g, GroupMembers: [...g.GroupMembers.filter(c => c.Id !== entityId), { ...char, Position: newPosition }] }
                SaveGroupToStorage(newG)
            }

            if (npc !== undefined) {
                const newG = { ...g, NpcStates: [...g.NpcStates.filter(c => c.Id !== entityId), { ...npc, Position: newPosition }] }
                SaveGroupToStorage(newG)
            }

            res(await this.GetGroup(null))
        })
    }
}

const GetDummyValues = (): GroupState => {

    const names = ["Quinn", "Vera", "Mekhai"]
    const seed = [1, 2, 3]
    return {
        GroupMembers: [...seed.map(i => {
            return {
                Id: i.toString(),
                Name: names[i - 1],
                Inventory: [],
                Stats: {
                    Health: Math.round(Math.random() * (2 * i + 10)),
                    MaxHealth: 2 * i + 10,
                    Dexterity: Math.round(Math.random() * 100),
                    Intelligence: Math.round(Math.random() * 100),
                    Strength: Math.round(Math.random() * 100)
                },
                Weapon:undefined
            }
        }), initialOwn],
        Maps: [{ Id: "mapId", MapName: "Unbekannter Ort", MapImageName: "ZuchlaborMap1", Active: true }],
        NpcStates: []
    };
}

const GroupStorageKey = "Group"
const GetGroupFromStorage = (): GroupState => {
    const result = localStorage.getItem(GroupStorageKey)

    if (result === null) {
        const initial = GetDummyValues()
        SaveGroupToStorage(initial)
        return initial
    }

    return JSON.parse(result)
}

const SaveGroupToStorage = (CharacterState: GroupState): void => {
    localStorage.setItem(GroupStorageKey, JSON.stringify(CharacterState))
}

const initialOwn: CharacterState = {
    Id: "0",
    Inventory: [
        {Item:Items.Medkit.Item, Amount:3},
        {Item:Items.HelperBot.Item, Amount:3},
        {Item:Items.BulkSN.Item, Amount:1},
        {Item:Items.P2000.Item, Amount:1},
        {Item:Items.Munition.Item, Amount:10}
    ],
    Stats: {
        MaxHealth: 20,
        Health: 17,
        Dexterity: 42,
        Intelligence: 12,
        Strength: 5
    },
    Name: "Helena",
    Weapon:undefined
}