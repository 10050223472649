import { FC } from "react";
import { CharacterState } from "../../Classes/CharacterState";
import { Card, ProgressBar } from "react-bootstrap";

export const CCharacterThumbnail: FC<{ character: CharacterState, onClick?: () => void, width: number, selected?:boolean }> = ({ character, onClick, width, selected }) => {
    return (
        <Card onClick={onClick} style={{ width: width, height: width + 80 }} bg={selected ? 'warning' : ''}>
            <Card.Body>
                <Card.Title>{character.Name}</Card.Title>
                <Card.Img src={`/images/${character.Name}.png`} />
                <ProgressBar
                    style={{ marginTop: 10, height: 25 }}
                    now={character.Stats.Health}
                    max={character.Stats.MaxHealth}
                    variant="danger"
                    label={`${character.Stats.Health} HP`}
                />
            </Card.Body>
        </Card>
    )
}