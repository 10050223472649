import { FC, useState } from "react";
import { Card } from "react-bootstrap";
import { CodexState, EntryUnlocks, KnowsEntry } from "../../../Classes/CodexState";
import { Link } from "react-router-dom";
import { AdditionalCard, AdditionalToElement } from "../../../Classes/AdditionalCard";
import { CodexApi } from "../../../Classes/CodexApi";

export interface PlanetDescription {
    Name:string,
    DescriptionText:JSX.Element,
    AdditionalCards?:AdditionalCard[],
    KnownSpecies:EntryUnlocks[],
    KnownLocations:EntryUnlocks[],
}

export const CPlanetEntry : FC<PlanetDescription> = ({Name, DescriptionText, KnownSpecies, KnownLocations, AdditionalCards}) => {

    const [context] = useState<CodexState>(CodexApi.FromCache())
    
    const KnownToElement = (prefix:string) => (ks:EntryUnlocks) : JSX.Element  => {
        const name = EntryUnlocks[ks].replace(prefix, "")

        if(KnowsEntry(context, ks)){
            return <Link key={name} to={`/Codex/${name}`}>{name}</Link>
        }

        return <></>
    }

    return <>
        <Card key="Main">
            <Card.Body>
                <Card.Title>{Name}</Card.Title>
                <Card.Img src={`/images/${Name}.png`}/>
                <Card.Text>
                    {DescriptionText}
                </Card.Text>
            </Card.Body>
        </Card>
        {AdditionalCards && AdditionalCards.map(AdditionalToElement)}
        <Card key="KnownLoc">
            <Card.Body>
                <Card.Title>Gefundene Orte</Card.Title>
                <Card.Text className="KnownSpecies">
                    {KnownLocations.map(KnownToElement("Location"))}
                </Card.Text>
            </Card.Body>
        </Card>
        <Card key="KnownSpecies">
            <Card.Body>
                <Card.Title>Entdeckte Lebewesen</Card.Title>
                <Card.Text className="KnownSpecies">
                    {KnownSpecies.map(KnownToElement("Creature"))}
                </Card.Text>
            </Card.Body>
        </Card>
    </>
}